import { MaterialSymbolsBreakingNews } from '@/assets/icons/comm/MaterialSymbolsBreakingNews';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import PageEnum from '@/enums/pageEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import { getNewsListApi } from '@/services/api/requestApi';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 新闻 页面
 */
export default () => {
  const { t } = useTranslation();

  /**
   * 查询参数
   */
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
  });

  /**
   * 消息列表数据
   */
  const { list, loading, loadMore, error, total, ...resetPageProps } =
    usePaginationPage({
      fn: getNewsListApi,
      params,
      setParams,
    });

  return (
    <div>
      <BaseTopNav title={t('新闻')}></BaseTopNav>
      <PullToRefresh
        loadMore={loadMore}
        total={total}
        list={list}
        error={error}
        loading={loading}
        {...resetPageProps}
      >
        {list.map((item: any) => {
          return (
            <div
              key={item.id}
              className=" flex px-4 py-3 gap-4 cursor-pointer"
              onClick={() => {
                history.push(`${PageEnum.NEWSDETAIL}?`, {
                  id: item.id,
                });
              }}
            >
              <div>
                <MaterialSymbolsBreakingNews className="text-2xl mt-1"></MaterialSymbolsBreakingNews>
              </div>
              <div className="w-full flex flex-col gap-2">
                <div className=" font-bold text-titleColor text-xl">
                  {item.title}
                </div>
                <div className="text-iconFontColor w-full line-clamp-2">
                  <FeaturesRenderText data={item} />
                </div>
                <div className=" text-iconFontColor text-xs">
                  {RenderUtil.convertTimestamp(item?.createTime)}
                </div>
              </div>
            </div>
          );
        })}
      </PullToRefresh>
    </div>
  );
};
